import * as React from "react";
import { useEffect } from "react";
import "./layout/styles.scss";
import BlogPostCard from "../components/BlogPostCard/BlogPostCard";
import {
  TitleSection,
  BlogBreadcrumbs,
  SEOMetaTags,
  CallToAction,
  GreyFooter,
  FundingBanner,
  PrevButton,
  NextButton
} from "../components";
import CategoryTabs from "../components/CategoryTabs/CategoryTabs";
import MainMenu from "../components/MainMenu/MainMenu";
import { IBlogPage } from "./interfaces";

const BlogPage = (data: IBlogPage): JSX.Element => {

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.opacity = "1";
    bodyStyle.transition = "opacity 0.3s";
  }, []);


  let { hasNextPage, hasPrevPage, nextPage, prevPage, items, seoMetaTags, menu, lang, footer, urlName, cta, c2c, pageTitle, banner, categoriesData } = data.pageContext;
  const prevLink = hasPrevPage &&
    <link rel="prev" href={`https://pushpushgo.com/${lang}/${urlName}/page/${prevPage}`}/>;
  const nextLink = hasNextPage &&
    <link rel="next" href={`https://pushpushgo.com/${lang}/${urlName}/page/${nextPage}`}/>;
  const pageTitleData = pageTitle && pageTitle.find(el => el.page === "blog");
  const pageName = pageTitleData && pageTitleData.name;
  const description = pageTitleData && pageTitleData.description;

  const alternateLocale = lang && (lang === "pl" ? "en" : "pl");
  const alternateLinks = [{
    locale: alternateLocale,
    link: `https://pushpushgo.com/${alternateLocale}/${urlName}/page/1/`,
  }];

  return (
    <>
      <MainMenu menu={menu} lang={lang} c2c={c2c}/>
      <SEOMetaTags tags={seoMetaTags}
                   lang={lang}
                   prevLink={prevLink}
                   nextLink={nextLink}
                   pageName={pageName}
                   alternateLinks={alternateLinks}/>
      <TitleSection propertyReference={[{ key: "title", value: `${pageName}` }, {
        key: "blog",
        value: "blog-version",
      }, { key: "color", value: "green-version" }, { key: "paragraph", value: `${description}` }]}/>
      <section className="subpage-section">
        <div className="content-limiter blog-container">
          <BlogBreadcrumbs pages={[{ key: "page", value: `${pageName}`, url: `/${lang}/${urlName}/page/1` }]}/>
          {categoriesData && categoriesData.length > 0 &&
          <CategoryTabs items={categoriesData} url={`${lang}/${urlName}/category/`}/>}
          <div className="cards-grid">
            {items.map(post => <BlogPostCard key={Math.random() + new Date().toDateString()} {...post} lang={lang}/>)}
          </div>
          <nav>
            {hasPrevPage && <PrevButton lang={lang} path={`/${lang}/${urlName}/page/${prevPage}`}/>}
            {hasNextPage && <NextButton lang={lang} path={`/${lang}/${urlName}/page/${nextPage}`}/>}
          </nav>
        </div>
      </section>
      <CallToAction cta={cta}/>
      <GreyFooter footer={footer} lang={lang}/>
      <FundingBanner banner={banner}/>
    </>
  );
};
export default BlogPage;
